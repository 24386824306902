import {Button, Cascader, Form, Input, Modal} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import AddNewPictures from '../AddNewPictures';
// @ts-ignore
import {debounce} from "lodash"
import {showFail, showNeedCompleteInfo, showSuccess} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {addHydrant, addRoad} from "../../api";
import PickUser from "../pickUser";

const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const AddHydrantModal = ({
                             open,
                             onClose,
                             areaOptions,
                             handleGetHydrant
                         }: { open: boolean, onClose: () => void, areaOptions: any, handleGetHydrant: any }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });

    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const confirmHydrantName = (e: any) => {
        form.setFieldsValue({"hydrantName": e})
    }

    const confirmRoad = (e: any) => {
        form.setFieldsValue({"road": e})
    }

    const confirmAddress = (e: any) => {
        form.setFieldsValue({"hydrantAddress": e})
    }


    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }


    const [loading, setLoading] = useState<boolean>(false);
    const handleAddNewRoad = () => {
        form.validateFields().then((value) => {
            let newHydrant = {
                roadName:value?.roadName,
                areaId: value?.area && value?.area[value?.area?.length - 1],
            }
            setLoading(true);
            addRoad(newHydrant).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetHydrant().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                        setLoc(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoc(undefined);
                setLoading(false)
            }} title={'道路新增'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleAddNewRoad, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    <Form.Item
                        name="area"
                        label="街道"
                        rules={[
                            {
                                required: true, message: "请选择XHS街道"
                            },
                        ]}
                    >
                        <Cascader style={{width: 250}}
                                  options={areaOptions}
                                  changeOnSelect={false} placeholder="街道/乡镇"/>
                    </Form.Item>


                    <Form.Item
                        name="roadName"
                        label="道路名称"
                        rules={[
                            {
                                required: true, message: "请输入道路名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入道路名称"/>
                    </Form.Item>

                </Form>

            </Modal>
            <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                               confirmLoc={(e: any) => {
                                   confirmLoc(e)
                               }} confirmName={confirmHydrantName} confirmAddress={confirmAddress}
                               confirmRoad={confirmRoad} repick={undefined}/>
        </div>
    );
};

export default AddHydrantModal;