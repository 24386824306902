import {Button, Form, Image} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from "./index.module.css"
import testSignPic from "../../../assets/images/sign.png"
import {Checkbox} from 'antd';
import {getHiddenDangerData} from "../../../api";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import locationIcon from "../../../assets/icons/location.svg";
import LocationModal from "../../../components/locationModal";

const SafeCheckRecord = () => {

    const [hiddenDanger,setHiddenDanger]=useState<any>()

    useEffect(()=>{
        const hash = window.location.hash; // Get the hash part of the URL
        const params = new URLSearchParams(hash.split('?')[1]); // Split at '?' and create URLSearchParams
        const dangerId=params.get('dangerId');
        getHiddenDangerData({dangerId:dangerId}).then((res)=>{
            setHiddenDanger(res.data[0])
            console.log("hiddenDanger: ",res.data)
        })

    },[])

    const [type,setType]=useState<any>()

    const printDocument = () => {
        const domElement: any = document.getElementById('my-node');

        html2canvas(domElement, {
            scale: 2,
            useCORS: true,
            width: domElement.scrollWidth,
            height: domElement.scrollHeight
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.5);
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const margin = 20; // 左右留出20px空白
            const contentWidth = pdfWidth - 2 * margin; // 内容宽度 = 页面宽度 - 左右空白
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            // 计算缩放因子
            const scaleFactor = contentWidth / canvasWidth;
             const scaledHeight = canvasHeight;

            let yPosition = 0;

            // while (yPosition < canvasHeight) {
                const pageCanvas = document.createElement('canvas');
                const pageContext = pageCanvas.getContext('2d');

                pageCanvas.width = canvasWidth;
                pageCanvas.height = Math.min(pdfHeight / scaleFactor, canvasHeight - yPosition);

                // 更新绘图位置
                // @ts-ignore
                pageContext.drawImage(canvas, 0, -yPosition, canvasWidth, canvasHeight);

                const pageImgData = pageCanvas.toDataURL('image/jpeg', 0.5);

                if (yPosition > 0) {
                    pdf.addPage();
                }
                pdf.addImage(pageImgData, 'JPEG', margin, 0, contentWidth, Math.min(scaledHeight, pdfHeight));

                // 增加 y 位置以生成下一页
                yPosition += pdfHeight / scaleFactor;
            // }

            pdf.save('download.pdf');
        });
    };

    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const handleCloseLocationModal = () => {
        setLocationModalOpen(false);
    }


    return (
        <div>
            <div className={styles.container}>
                <div className={styles.leftArea}/>
                <div className={styles.mainArea} id="my-node">
                    <div className={styles.title}>
                        消防救援站消防安全检查记录表
                    </div>

                    <div className={styles.table}>
                        <div className={styles.div1}>
                            单位、场所名称
                        </div>

                        <div className={styles.div2}>
                            {hiddenDanger?.communityName}
                        </div>

                        <div className={styles.div3}>
                            检查时间
                        </div>

                        <div className={styles.div4}>
                            {hiddenDanger?.taskStartDate}
                        </div>

                        <div className={styles.div5}>
                            地址
                        </div>

                        <div className={styles.div6}>
                            {hiddenDanger?.dangerAddress}
                        </div>

                        <div className={styles.div7}>
                            消防安全责任人（管理人）及联系方式
                        </div>

                        <div className={styles.div8}>
                            {hiddenDanger?.safePersonAndContact}
                        </div>

                        <div className={styles.div9}>
                            建筑层数
                        </div>

                        <div className={styles.div10}>
                            <div>地上 <span style={{color: '#e90b2d'}}> {hiddenDanger?.upperLayer}</span> 层</div>
                            <div>地下 <span style={{color: '#e90b2d'}}> {hiddenDanger?.underLayer}</span> 层</div>
                        </div>

                        <div className={styles.div11}>
                            使用性质
                        </div>

                        <div className={styles.div12}>
                            {hiddenDanger?.natureOfUse}
                        </div>

                        <div className={styles.div13}>
                            <div>
                                检查内容
                            </div>
                            <div style={{margin: "1vh 0"}}>
                                和
                            </div>
                            <div>
                                情况记录
                            </div>
                        </div>

                        <div className={styles.div14}>
                            消防安全管理
                        </div>

                        <div className={styles.div15}>
                            <div className={styles.div15_item}>
                                <div>1. 违章用火、用电情况:</div>
                                <div style={{marginLeft: '20px', fontSize: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '未发现',
                                        value: '未发现'
                                    },
                                    {
                                        label: '发现并制止',
                                        value: '发现并制止'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireSafetyManage1]}/></div>
                            </div>

                            <div className={styles.div15_item}>
                                <div>2. 生产、储存、经营场所违章住人情况:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '未发现',
                                        value: '未发现'
                                    },
                                    {
                                        label: '发现并制止',
                                        value: '发现并制止'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireSafetyManage2]}/></div>
                            </div>

                            <div className={styles.div15_item}>
                                <div>3. 人员密集场所在门窗上设置影响逃生和灭火救援的障碍物情况:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '未发现',
                                        value: '未发现'
                                    },
                                    {
                                        label: '发现并提示整改',
                                        value: '发现并提示整改'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireSafetyManage3]}/></div>
                            </div>

                            <div className={styles.div15_item}>
                                <div>4. 消防控制室值班人员在岗在位情况:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '在岗在位',
                                        value: '在岗在位'
                                    },
                                    {
                                        label: '未按要求在岗在位',
                                        value: '未按要求在岗在位'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireSafetyManage4]}/></div>
                            </div>

                            <div className={styles.div15_item}>
                                <div>5. 消防控制室值班人员持证上岗情况:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '两名持证人员值班',
                                        value: '两名持证人员值班'
                                    },
                                    {
                                        label: '一名持证人员值班',
                                        value: '一名持证人员值班'
                                    },
                                    {
                                        label: '值班人员无证',
                                        value: '值班人员无证'
                                    },
                                    {
                                        label: '无人员值班',
                                        value: '无人员值班'
                                    }
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireSafetyManage5]}/></div>
                            </div>

                            <div className={styles.div15_item}>
                                <div>6. 建筑消防设施维护保养记录情况:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '有',
                                        value: '有'
                                    },
                                    {
                                        label: '无',
                                        value: '无'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireSafetyManage6]}/></div>
                            </div>

                            <div className={styles.div15_item}>
                                <div>7. 电动自行车违规停放充电、进楼入户情况:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '未发现',
                                        value: '未发现'
                                    },
                                    {
                                        label: '发现并制止',
                                        value: '发现并制止'
                                    },
                                    {
                                        label: '发现但无法当场整改',
                                        value: '发现但无法当场整改'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireSafetyManage7]}/></div>
                            </div>

                            <div className={styles.div15_item}>
                                <div>8. 电动自行车销售维修、集中停放充电点是否使用实体墙与其他部位进行完全的防火分隔:
                                </div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '未发现',
                                        value: '未发现'
                                    },
                                    {
                                        label: '发现并制止',
                                        value: '发现并制止'
                                    },
                                    {
                                        label: '发现但无法当场整改',
                                        value: '发现但无法当场整改'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireSafetyManage8]}/></div>
                            </div>

                            <div className={styles.div15_item}>
                                <div>具体情形描述:</div>
                                <div style={{marginLeft: '20px'}}>
                                    {[hiddenDanger?.fireSafetyManageDetail]}
                                </div>
                            </div>
                        </div>

                        <div className={styles.div16}>
                            灭火救援条件
                        </div>

                        <div className={styles.div17}>
                            <div className={styles.div17_item}>
                                <div>1. 消防车通道、消防救援场地:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '畅通',
                                        value: '畅通'
                                    },
                                    {
                                        label: '被堵塞、占用',
                                        value: '被堵塞、占用'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fightingAndRescueConditions1]}/></div>
                            </div>

                            <div className={styles.div17_item}>
                                <div>2. 消防救援窗口:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '畅通',
                                        value: '畅通'
                                    },
                                    {
                                        label: '被堵塞、占用',
                                        value: '被堵塞、占用'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fightingAndRescueConditions2]}/></div>
                            </div>

                            <div className={styles.div17_item}>
                                <div>3. 消防水池:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '符合使用要求',
                                        value: '符合使用要求'
                                    },
                                    {
                                        label: '水量不足',
                                        value: '水量不足'
                                    },
                                    {
                                        label: '组件故障或者损坏',
                                        value: '组件故障或者损坏'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fightingAndRescueConditions3]}/></div>
                            </div>

                            <div className={styles.div17_item}>
                                <div>4. 室外消火栓:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '完好有效',
                                        value: '完好有效'
                                    },
                                    {
                                        label: '无水',
                                        value: '无水'
                                    },
                                    {
                                        label: '损坏',
                                        value: '损坏'
                                    },
                                    {
                                        label: '被遮挡或者圈占',
                                        value: '被遮挡或者圈占'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fightingAndRescueConditions4]}/></div>
                            </div>

                            <div className={styles.div17_item}>
                                <div>5. 水泵接合器:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '无',
                                        value: '无'
                                    },
                                    {
                                        label: '完好有效',
                                        value: '完好有效'
                                    },
                                    {
                                        label: '损坏',
                                        value: '损坏'
                                    }
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fightingAndRescueConditions5]}/></div>
                            </div>

                            <div className={styles.div17_item}>
                                <div>具体情形描述:</div>
                                <div style={{marginLeft: '20px'}}>
                                    {[hiddenDanger?.fightingAndRescueConditionsDetail]}
                                </div>
                            </div>

                        </div>

                        <div className={styles.div18}>
                            建筑消防设施
                        </div>

                        <div className={styles.div19}>
                            <div className={styles.div19_item}>
                                <div>1.疏散通道、安全出口:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '畅通',
                                        value: '畅通'
                                    },
                                    {
                                        label: '被堵塞、占用',
                                        value: '被堵塞、占用'
                                    },
                                    {
                                        label: '被锁闭',
                                        value: '被锁闭'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireInstallation1]}/></div>
                            </div>

                            <div className={styles.div19_item}>
                                <div>2. 室内消火栓:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '完好有效',
                                        value: '完好有效'
                                    },
                                    {
                                        label: '无水',
                                        value: '无水'
                                    },
                                    {
                                        label: '损坏',
                                        value: '损坏'
                                    },
                                    {
                                        label: '被遮挡或者圈占',
                                        value: '被遮挡或者圈占'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireInstallation2]}/></div>
                            </div>

                            <div className={styles.div19_item}>
                                <div>3. 自动喷水灭火系统:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '完好有效',
                                        value: '完好有效'
                                    },
                                    {
                                        label: '无水',
                                        value: '无水'
                                    },
                                    {
                                        label: '水压不足',
                                        value: '水压不足'
                                    },
                                    {
                                        label: '无法启动',
                                        value: '无法启动'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireInstallation3]}/></div>
                            </div>

                            <div className={styles.div19_item}>
                                <div>4. 应急照明和疏散指示标志:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '完好有效',
                                        value: '完好有效'
                                    },
                                    {
                                        label: '损坏',
                                        value: '损坏'
                                    }
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireInstallation4]}/></div>
                            </div>

                            <div className={styles.div19_item}>
                                <div>5. 消防电梯:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '能迫降到首层',
                                        value: '能迫降到首层'
                                    },
                                    {
                                        label: '不能迫降到首层',
                                        value: '不能迫降到首层'
                                    }
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireInstallation5]}/></div>
                            </div>

                            <div className={styles.div19_item}>
                                <div>6. 应急广播:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '不涉及',
                                        value: '不涉及'
                                    },
                                    {
                                        label: '完好有效',
                                        value: '完好有效'
                                    },
                                    {
                                        label: '无法正常使用',
                                        value: '无法正常使用'
                                    }
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireInstallation6]}/></div>
                            </div>

                            <div className={styles.div19_item}>
                                <div>具体情形描述:</div>
                                <div style={{marginLeft: '20px'}}>
                                    {hiddenDanger?.fireInstallationDetail}
                                </div>
                            </div>

                        </div>

                        <div className={styles.div20}>
                            消防组织
                        </div>

                        <div className={styles.div21}>
                            <div className={styles.div21_item}>
                                <div>1. 志愿消防队（微型消防站）:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '未建立',
                                        value: '未建立'
                                    },
                                    {
                                        label: '人员器材配备齐全',
                                        value: '人员器材配备齐全'
                                    },
                                    {
                                        label: '人员器材缺乏',
                                        value: '人员器材缺乏'
                                    },
                                    {
                                        label: '有定期训练及工作记录',
                                        value: '有定期训练及工作记录'
                                    },
                                    {
                                        label: '无训练及工作',
                                        value: '无训练及工作'
                                    },
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))} value={[hiddenDanger?.fireOrg1]}/></div>
                            </div>
                            <div className={styles.div21_item}>
                                <div>2. 灭火和应急疏散预案:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '未制定',
                                        value: '未制定'
                                    },
                                    {
                                        label: '制定',
                                        value: '制定'
                                    }
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))}
                                                                                  value={[hiddenDanger?.fireOrg2]}/>
                                </div>
                            </div>
                            <div className={styles.div21_item}>
                                <div>3. 消防演练情况:</div>
                                <div style={{marginLeft: '20px'}}><Checkbox.Group options={[
                                    {
                                        label: '有记录',
                                        value: '有记录'
                                    },
                                    {
                                        label: '无记录',
                                        value: '无记录'
                                    }
                                ].map(option => ({
                                    ...option,
                                    label: <span style={{fontSize: '18px'}}>{option.label}</span>
                                }))}
                                                                                  value={[hiddenDanger?.fireOrg3]}/>
                                </div>
                            </div>
                            <div className={styles.div21_item}>
                                <div>具体情形描述:</div>
                                <div style={{marginLeft: '20px'}}>
                                    {hiddenDanger?.fireOrgDetail}
                                </div>
                            </div>
                        </div>

                        <div className={styles.div22}>
                            开展消防宣传指导情况
                        </div>

                        <div className={styles.div23}>
                            {hiddenDanger?.publicityAndGuidance}
                        </div>

                        <div className={styles.div24}>
                            备注
                        </div>

                        <div className={styles.div25}>
                            {hiddenDanger?.remark}
                        </div>

                        <div className={styles.div26}>
                            <div className={styles.signatureItem}>
                                <div>
                                    消防救援站指战员（签字）：
                                </div>
                                <div>
                                    <img src={hiddenDanger?.commanderSignUrl} width={80} height={80} alt=""/>
                                </div>
                            </div>
                            <div className={styles.signatureDate}>
                                2024年9月25日
                            </div>
                        </div>

                        <div className={styles.div27}>
                            <div className={styles.signatureItem}>
                                <div>
                                    单位（场所）负责人（签字）：
                                </div>
                                <div>
                                    <img src={hiddenDanger?.masterSignUrl} width={80} height={80} alt=""/>
                                </div>
                            </div>
                            <div className={styles.signatureDate}>
                                2024年9月26日
                            </div>
                        </div>

                        {hiddenDanger?.backId ?<div className={styles.div28}>
                            {'辖区消防救援大队接收情况'}
                        </div>:''}
                        {hiddenDanger?.backId ? <div className={styles.div29}>
                            <div className={styles.signatureItem}>
                                <div>
                                    接收人（签字）：
                                </div>
                                <div>
                                    <img src={hiddenDanger?.receiverSign} width={80} height={80} alt=""/>
                                </div>
                            </div>
                            <div className={styles.signatureDate}>
                                2024年9月26日
                            </div>
                        </div> : <div className={styles.div30}>
                            已现场确认
                        </div>}

                    </div>
                </div>
                <div className={styles.rightArea}>
                <Button style={{marginTop: '4vh'}}
                            type={'primary'} onClick={printDocument}>导出为pdf
                    </Button>
                </div>

            </div>

            <div style={{marginTop: '30px', marginBottom: '30px', marginLeft: '10vw'}}>
                <Form>
                    <Form.Item label={"填表定位"}>
                        <div
                            className={styles.content1}>
                            <img style={{marginLeft: '10px', cursor: 'pointer'}} width={25} height={25}
                                 src={locationIcon}
                                 alt="" onClick={() => {
                                setLocationModalOpen(true);
                                setType(1)
                            }}/>
                        </div>
                    </Form.Item>
                    {/*<Form.Item label={"确认定位"}>*/}
                    {/*    <div*/}
                    {/*        className={styles.content1}>*/}
                    {/*        <img style={{marginLeft: '10px', cursor: 'pointer'}} width={25} height={25}*/}
                    {/*             src={locationIcon}*/}
                    {/*             alt="" onClick={() => {*/}
                    {/*            setLocationModalOpen(true);*/}
                    {/*            setType(2)*/}
                    {/*        }}/>*/}
                    {/*    </div>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label={"现场照片"}>
                        {hiddenDanger?.dangerPictures
                            .filter((image: any) => image.pictureType === 1)  // 过滤出 pictureType 为 1 的图片
                            .map((image: any) => (
                                <div key={image.key}>  {/* 把 key 移到外层的 div */}
                                    <Image
                                        style={{ paddingTop: '20px' }}
                                        width={200}
                                        height={200}
                                        src={image.url}
                                    />
                                </div>
                            ))}

                    </Form.Item>
                    {/*<Form.Item label={"确认照片"}>*/}
                    {/*    {hiddenDanger?.dangerPictures*/}
                    {/*        .filter((image: any) => image.pictureType === 2)  // 过滤出 pictureType 为 1 的图片*/}
                    {/*        .map((image: any) => (*/}
                    {/*            <div key={image.key}>  /!* 把 key 移到外层的 div *!/*/}
                    {/*                <Image*/}
                    {/*                    style={{ paddingTop: '20px' }}*/}
                    {/*                    width={200}*/}
                    {/*                    height={200}*/}
                    {/*                    src={image.url}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        ))}*/}

                    {/*</Form.Item>*/}
                </Form>
            </div>
            <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                           lat={type==1?hiddenDanger?.communityLat:hiddenDanger?.confirmLat} lng={type==1?hiddenDanger?.communityLng:hiddenDanger?.confirmLng}/>

        </div>
    );
};

export default SafeCheckRecord;