import React, {useEffect, useState} from 'react';
import {Button, Divider, Input, Popconfirm, Select, Space, Table, Tag, TreeSelect} from "antd";
import {deleteDangerTask, getDeptTree, getFireRecordDataPage} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import styles from "../exercise/index.module.css";
import AddExerciseTaskDrawer from "../../../components/addExerciseTaskDrawer";
// import {FireRecordDetailModal} from "../../../components/FireRecordDetailModal";

interface depttree {
    value?: string,
    title?: string,
    children?: any[],
}

function tree(list: depttree[], res: any[]) {
    for (let i = 0; i < res.length; i++) {
        if (res[i].children) {
            // @ts-ignore
            list.push({value: res[i].deptId, title: res[i].name, children: []})
        } else {
            // @ts-ignore
            list.push({value: res[i].deptId, title: res[i].name})
        }
        // @ts-ignore
        if (res[i].children) {
            // @ts-ignore
            tree(list[i].children, res[i].children)
        }
    }
    return list
}

const FireRecord = () => {
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNum, setPageNum] = useState<number>(1);
    const [total, setTotal] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [fireRecordId, setFireRecordId] = useState<string | number>("");
    const [fireRecordData, setFireRecordData] = useState<any[]>([]);

    const [depts, setDepts] = useState<any[]>([]);
    const handleGetDepts=()=>{
        getDeptTree().then((res: any) => {
            if (res.code === 200) {
                let a: depttree[] = []
                let b = []
                b.push(res.data)
                let list = tree(a, b)
                setDepts(list);
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.error(err)
        })
    }
    useEffect(()=>{
        handleGetDepts()
    },[])
    const onPageChange = (_page: number) => {
        setPageNum(_page);
    };

    const onPageSizeChange = (_page: number, _pageSize: number) => {
        setPageSize(_pageSize);
    };

    const [searchCondition, setSearchCondition] = useState({});
    const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

    let params: any = {}
    const handleGetFireRecord = async (pageNum: number, pageSize: number, searchCondition?: any) => {
        setLoading(true);

        params.pageSize = pageSize;
        params.pageNum = pageNum;
        if (searchCondition?.taskName) {
            params.fireCompanyName = searchCondition.taskName;
        }
        if (searchCondition?.state!==undefined) {
            params.state = searchCondition.state;
        }
        if (searchCondition?.deptId) {
            params.writeDeptId = searchCondition.deptId;
        }
        console.log(params)
        getFireRecordDataPage(params).then((res: any) => {
            if (res.code === 200) {
                setLoading(false);
                setTotal(Number(res?.data?.total));
                setFireRecordData(res.data?.records)
            } else {
                showFail(res?.msg)
            }
        }).catch((err: any) => {
            console.error(err)
        })
    }

    useEffect(() => {
        handleGetFireRecord(pageNum, pageSize, newSearchCondition)
    }, [pageSize, pageNum])

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleDetailModalClose = () => {
        setModalOpen(false);
    }

    const columns = [
        {
            title: '起火单位',
            dataIndex: 'fireCompanyName',
            key: 'fireCompanyName',
        },
        {
            title: '地址',
            dataIndex: 'fireAddress',
            key: 'fireAddress',
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
        },
        {
            title: '报警时间',
            dataIndex: 'alarmTime',
            key: 'alarmTime',
        },
        {
            title: '战斗结束时间',
            dataIndex: 'fightEndTime',
            key: 'fightEndTime',
        },
        {
            title: '发起人',
            dataIndex: ['writeUser','username'],
        },
        {
            title: '接收人',
            dataIndex: ['receiveUser','username'],
        },
        {
            title: '发起部门',
            dataIndex: ['writeUserDept','name'],
        },
        {
            title: '接收部门',
            dataIndex: ['receiveUserDept','name'],
        },
        {
            title: '详情',
            dataIndex: 'fireRecordId',
            key: 'fireRecordId',
            render: ((fireRecordId: any, record: any) => (
                <>
                    <Button type={'primary'} onClick={() => {
                        // @ts-ignore
                        window.open("/#/fire_accident_record?fireRecordId=" + fireRecordId, '_blank');
                        setFireRecordId(fireRecordId)
                    }}>查看详情</Button>
                    <Popconfirm title={'是否确认删除该隐患排查任务?'} onConfirm={()=>{
                        deleteDangerTask({fireRecordId}).then((res:any)=>{
                            if(res.code===200){
                                handleGetFireRecord(pageNum,pageSize,newSearchCondition).then(()=>{
                                    showSuccess(res?.msg)
                                })
                            }else{
                                showFail(res?.msg)
                            }
                        })
                    }}>
                        <Button style={{marginLeft:'20px'}} danger>
                            删除
                        </Button>
                    </Popconfirm>

                </>

            ))
        },
    ]


    const handleStateChange = (v: any) => {
        setSearchCondition({...searchCondition, state:v})
    }

    const handleNameChange = (e: any) => {
        setSearchCondition({...searchCondition, taskName: e.target.value})
    }

    const handleDeptIdChange = (e:any) => {
        setSearchCondition({...searchCondition, deptId:e})
    }

    const handleCommunityIdChange = (e:any) => {
        setSearchCondition({...searchCondition, communityId:e})
    }

    const [addDrawerVisible,setAddDrawerVisible]=useState<boolean>(false);
    const handleCloseAddDrawer=()=>{
        setAddDrawerVisible(false);
    }


    const [communityList,setCommunityList]=useState<any[]>([])

    return (
        <div>
            <div className={styles.mainHead}>
                <div className={styles.searchContent}>
                    <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                            options={[
                                {value: "待确认", label: "待确认"},
                                {value: "已确认", label: "已确认"},
                            ]}
                            onChange={handleStateChange} placeholder={'状态'} allowClear/>

                    <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                           onChange={handleNameChange} placeholder={'起火单位'} allowClear/>



                    <TreeSelect
                        placeholder={"请选择发起部门"}
                        showSearch
                        style={{width: '250px', marginRight: '30px', marginTop: '10px'}}
                        filterTreeNode={(inputValue, treeNode) =>
                            (treeNode as any).title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                        }
                        // value={deptTree}
                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                        allowClear
                        treeDefaultExpandAll
                        onChange={handleDeptIdChange}
                        treeData={depts || []}
                    />

                    {/*<Cascader onChange={handleAreaChange} style={{width: 250, marginRight: '30px', marginTop: '10px'}}*/}
                    {/*          options={areaTreeTran ? [areaTreeTran] : []}*/}
                    {/*          changeOnSelect placeholder="县区/街道/乡镇" allowClear/>*/}


                </div>
                <div className={styles.searchButton}>

                    <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                        handleGetFireRecord(1, 10, searchCondition);
                        setPageNum(1);
                        setPageSize(10);
                        setNewSearchCondition(searchCondition)
                    }}>查询
                    </Button>

                    {/*<Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {*/}
                    {/*    setAddDrawerVisible(true)*/}
                    {/*}}>新增*/}
                    {/*</Button>*/}

                </div>

            </div>


            <Table rowKey={'fireRecordId'} columns={columns} dataSource={fireRecordData}
                   loading={loading}
                   pagination={{
                       current: pageNum,
                       onChange: onPageChange,
                       onShowSizeChange: onPageSizeChange,
                       total: total,
                       showSizeChanger: true,
                       defaultPageSize: pageSize,
                       pageSizeOptions: ['10', '20', '50', '100'],
                   }}/>

            {/*<FireRecordDetailModal open={modalOpen}*/}
            {/*                         onCancel={handleDetailModalClose}*/}
            {/*                         fireRecordId={fireRecordId as string}*/}
            {/*/>*/}

            <AddExerciseTaskDrawer open={addDrawerVisible} onCancel={handleCloseAddDrawer} type={1} handleGetTask={async () => {
                await handleGetFireRecord(pageNum, pageSize, newSearchCondition)
            }}/>
        </div>
    );
};

export default FireRecord;