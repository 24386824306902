import React, {useEffect, useRef, useState} from 'react';
import "./index.css"
// @ts-ignore
import htmlDocx from 'html-docx-js/dist/html-docx';
import html2canvas from 'html2canvas';
// @ts-ignore
import jsPDF from 'jspdf';

import {useLocation} from "react-router-dom";
import { Button } from 'antd';

export const Report = () => {
    const location =useLocation();
    const reportId=location?.search?.split("=")[1];
    console.log('reportId',reportId)


    const year=2022;
    const [record,setRecord]=useState<any>({bills:[
            {
                billId:11,
                month:1,
                company:'a',
                pensionBase:12,
                pensionPersonal:11,
                pensionIn:22
            },
            {
                billId:12,
                month:2,
                company:'b',
                pensionBase:12,
                pensionPersonal:11,
                pensionIn:22
            },
            {
                billId:13,
                month:3,
                company:'c',
                pensionBase:12,
                pensionPersonal:11,
                pensionIn:22
            }
        ]})

    const [loading,setLoading]=useState<boolean>(false);
    const htmlRef:any = useRef(null);
    const convertToWord = async () => {
        setLoading(true);
        const htmlContent = htmlRef.current.innerHTML;
        const converted = htmlDocx.asBlob(htmlContent);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(converted);
        link.download = 'document.docx';
        document.body.appendChild(link);
        link.click();
        setLoading(false);
    };


    const printDocument = () => {
        // 获取需要转换的DOM节点
        // const domElement:any = document.getElementById('my-node');
        //
        // html2canvas(domElement, { scale: 2 }).then((canvas) => {
        //     const domElement:any = document.getElementById('my-node');
        //     html2canvas(domElement, { scale: 2, useCORS: true }).then((canvas) => {
        //         const imgData = canvas.toDataURL('image/jpeg',0.5);
        //         const pdf = new jsPDF({
        //             orientation: 'landscape',
        //             unit: 'px',
        //             format: [canvas.width, canvas.height],
        //             putOnlyUsedFonts: true,
        //             floatPrecision: 16,
        //         });
        //         pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        //         pdf.save('download.pdf');
        //     });
        // });

        const domElement:any = document.getElementById('my-node');

        // 获取DOM的高度
        const domWidth = domElement.offsetWidth;
        const domHeight = domElement.scrollHeight;

        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: [domWidth, domHeight]
        });

        const pageHeight = pdf.internal.pageSize.getHeight();

        // 用html2canvas截取每一段
        let position = 0;

        const generatePDF = () => {
            // Adjust for the current scroll position
            html2canvas(domElement, {
                scale: 2,  // High resolution scaling
                scrollY: -position // Move the viewport to the next slice
            }).then((canvas:any) => {
                const imgData = canvas.toDataURL('image/jpeg');
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                // Add image to the PDF
                pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);

                // Prepare for the next section
                position += pdfHeight;

                // Check if we need to create more pages
                if (position < domHeight) {
                    pdf.addPage();
                    generatePDF();  // Recursively generate the next section
                } else {
                    pdf.save('download.pdf');  // Save when the last page is done
                }
            });
        };

        generatePDF();
    };


    return (
        <div ref={htmlRef} id="my-node">
            <div className='notice'>
                <div className={'title'}>
                    <div><Button loading={loading} onClick={printDocument}>导出为word</Button></div>
                    <div className='title-1'>SHBX</div>
                    {/*<img src={logo} className={'logo'}  alt="" style={{margin:'0 5px'}}></img>*/}
                    <div className='title-2'>中国社会保险 <span className={'title-2-sec'} >长兴社保</span></div>
                </div>

                <div className={'secLine'} />

                <div className={'text'}>
                    <div className='text-title'>社会保险个人权益记录单服务工作宣传提纲</div>
                    <div className='text-paragraph'>
                        <div className='text-paragraph-title'>
                            何谓社会保险个人权益记录单
                        </div>
                        <div className='text-paragraph-content'>
                            社会保险个人权益记录单是反映参保人员及其单位履行社会保险义务，享受社会保险权益状况的单据，是公民参加社会保险和享受社会保险待遇的核心凭证。
                        </div>
                    </div>

                    <div className='text-paragraph'>
                        <div className='text-paragraph-title'>
                            社会保险个人权益记录单寄送对象
                        </div>
                        <div className='text-paragraph-content'>
                            即参保对象分为城镇职工和城居民两类，其中城镇职工又分为在职职工和退休人员。
                        </div>
                    </div>


                    <div className='text-paragraph'>
                        <div className='text-paragraph-title'>
                            社会保险个人权益记录单寄送工作安排
                        </div>
                        <div className='text-paragraph-content'>
                            社会保险经办机构在每一自然年度结束，将参保人员上一自然年度个人权益记录单以电子邮件方式发给参保人员。
                        </div>
                    </div>


                    <div className='text-paragraph'>
                        <div className='text-paragraph-title'>
                            温馨提示:
                        </div>
                        <div className='text-paragraph-content-triangle'>
                            请各参保人员收到个人权益记录单后，及时对记录单内容进行核对，如有异议，请到县社保办进行核对、处理;
                            您还可以通拨打电话6025969(社保办)，6207712(邮政公司)，查询个人权益记录相关情况，如有权益记录差错和疑议您带来的不便、敬请谅解!我们将竭诚为您提供最快、最优质的服务，谢谢合作!
                        </div>

                        <div className='text-paragraph-content-triangle' style={{marginTop:'5px'}}>
                            各参保人员如有住址或邮寄地址变动，请及时回寄回执，县邮政公司变更信息，联系电话: 6207712(邮政公司)。
                        </div>
                    </div>
                </div>

                <div className={'bottomArea'}>
                    <div className='rightBottom'>
                        <div className={'rightBottom-paragraph'}>
                            <p>长</p><p>兴</p><p>县</p><p>社</p><p>保</p><p>中</p><p>心</p>
                        </div>

                        <div className={'rightBottom-paragraph'}>
                            <p>长</p><p>兴</p><p>县</p><p>邮</p><p>政</p><p>分</p><p>公</p><p>司</p>
                        </div>

                        <div className={'rightBottom-paragraph'}>
                            <p>二</p><p>0</p><p>二</p><p>三</p><p>年</p><p> 十</p><p>月</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='record'>
                <div className='recordTitleArea'>
                    <div className='recordTitle'>
                        养老保险<span style={{fontFamily:"宋体, serif"}}>“</span>长缴多得{" "}多缴多得<span style={{fontFamily:"宋体, serif"}}>”</span>
                    </div>

                    <div className='recordImageArea'>
                        {/*<img className='recordImage' src={qrCode} alt=""/>*/}
                        <div>扫一扫</div>
                        <div>关注长兴人社</div>
                    </div>
                </div>


                <div className='tableTitle'>
                    {year}年度长兴县社会保险个人权益记录单
                </div>

                <div className='secTableTitle'>
                    ({year}年1月至{year}年12月)
                </div>

                <div className='table'>
                    <div className='rowOneParent'>
                        <div className="row1-div1">姓名</div>
                        <div className="row1-div2">xxx</div>
                        <div className="row1-div3">社会保障号码</div>
                        <div className="row1-div4">24252</div>
                        <div className="row1-div5">本年缴费月数</div>
                        <div className="row1-div6">12</div>
                        <div className="row1-div7">本年养老保险账户储存额</div>
                        <div className="row1-div8">55</div>
                    </div>

                    <div className='rowTwoParent'>
                        <div className="row2-div1">联系电话</div>
                        <div className="row2-div2">222</div>
                        <div className="row2-div3">地址</div>
                        <div className="row2-div4">1111</div>
                        <div className="row2-div5">个人账户记账利息为6.12%</div>
                    </div>

                    <div className='rowThreeParent'>
                        <div className="row3-div1">本年度个人缴费明细</div>
                    </div>


                    <div className='rowFourParent'>
                        <div className="row4-div1">年月</div>
                        <div className="row4-div2">缴费单位</div>
                        <div className="row4-div3">养老</div>
                        {/*<div className="row4-div4">医疗</div>*/}
                        <div className="row4-div5">缴费基数</div>
                        <div className="row4-div6">个人缴费</div>
                        <div className="row4-div7">账户划入</div>
                        {/*<div className="row4-div8">个人缴费</div>*/}
                        {/*<div className="row4-div9">账户划入</div>*/}
                    </div>

                    {record?.bills?.map((b:any)=>(
                        <div className='rowEachMonth' key={b?.billId}>
                            <div className="month-div1">{b?.month}</div>
                            <div className="month-div2">{b?.company}</div>
                            <div className="month-div3">{b?.pensionBase}</div>
                            <div className="month-div4">{b?.pensionPersonal}</div>
                            <div className="month-div5">{b?.pensionIn}</div>
                            {/*<div className="month-div6">{b?.medicalPersonal}</div>*/}
                            {/*<div className="month-div7">{b?.medicalIn}</div>*/}
                        </div>
                    ))}


                </div>


            </div>

        </div>

    );
};

export default Report;