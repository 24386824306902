import {Button, Form, Image} from 'antd';
import React, {useEffect, useState} from 'react';
import styles from "./index.module.css"
import testSignPic from "../../../assets/images/sign.png"
import {Checkbox} from 'antd';
import {getFireRecordData} from "../../../api";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// @ts-ignore
import html2pdf from 'html2pdf.js';
import locationIcon from "../../../assets/icons/location.svg";
import LocationModal from "../../../components/locationModal";

const SafeCheckRecord = () => {

    const [fireRecord,setFireRecord]=useState<any>()

    const printDocument = () => {
        const domElement: any = document.getElementById('my-node');

        html2canvas(domElement, {
            scale: 2,
            useCORS: true,
            width: domElement.scrollWidth,
            height: domElement.scrollHeight
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.5);
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: 'a4'
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const margin = 20; // 左右留出20px空白
            const contentWidth = pdfWidth - 2 * margin; // 内容宽度 = 页面宽度 - 左右空白
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            // 计算缩放因子
            const scaleFactor = contentWidth / canvasWidth;
            const scaledHeight = canvasHeight * scaleFactor;

            let yPosition = 0;

            while (yPosition < canvasHeight) {
                const pageCanvas = document.createElement('canvas');
                const pageContext = pageCanvas.getContext('2d');

                pageCanvas.width = canvasWidth;
                pageCanvas.height = Math.min(pdfHeight / scaleFactor, canvasHeight - yPosition);
                pageCanvas.height= pageCanvas.height
                // 更新绘图位置
                // @ts-ignore
                pageContext.drawImage(canvas, 0, -yPosition, canvasWidth, canvasHeight);

                const pageImgData = pageCanvas.toDataURL('image/jpeg', 0.5);

                if (yPosition > 0) {
                    pdf.addPage();
                }
                pdf.addImage(pageImgData, 'JPEG', margin, 0, contentWidth, Math.min(scaledHeight, pdfHeight));

                // 增加 y 位置以生成下一页
                yPosition += pdfHeight / scaleFactor;
            }

            pdf.save('download.pdf');
        });
    };



    useEffect(()=>{
        const hash = window.location.hash; // Get the hash part of the URL
        const params = new URLSearchParams(hash.split('?')[1]); // Split at '?' and create URLSearchParams
        const fireRecordId=params.get('fireRecordId');
        getFireRecordData({fireRecordId:fireRecordId}).then((res)=>{
            setFireRecord(res.data[0])
            console.log("fireRecord: ",res.data)
        })

    },[])
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const handleCloseLocationModal = () => {
        setLocationModalOpen(false);
    }
    return (
        <div>
            <div className={styles.container}>

                <div className={styles.leftArea}/>
                <div className={styles.mainArea} id="my-node">
                    <div className={styles.title}>
                        火灾事故调查联勤记录表
                    </div>

                    <div className={styles.table}>
                        <div className={styles.div1}>
                            起火单位
                        </div>

                        <div className={styles.div2}>
                            {fireRecord?.fireCompanyName}
                        </div>

                        <div className={styles.div3}>
                            地址
                        </div>

                        <div className={styles.div4}>
                            {fireRecord?.fireAddress}
                        </div>

                        <div className={styles.div5}>
                            联系人
                        </div>

                        <div className={styles.div6}>
                            {fireRecord?.contactName}
                        </div>

                        <div className={styles.div7}>
                            联系电话
                        </div>

                        <div className={styles.div8}>
                            {fireRecord?.contactMobile}
                        </div>

                        <div className={styles.div9}>
                            报警时间
                        </div>

                        <div className={styles.div10}>
                            {fireRecord?.alarmTime}
                        </div>

                        <div className={styles.div11}>
                            到场时间
                        </div>

                        <div className={styles.div12}>
                            {fireRecord?.arriveTime}
                        </div>

                        <div className={styles.div13}>
                            报警人
                        </div>

                        <div className={styles.div14}>
                            {fireRecord?.alarmPersonName}
                        </div>

                        <div className={styles.div15}>
                            报警电话
                        </div>

                        <div className={styles.div16}>
                            {fireRecord?.alarmPersonMobile}
                        </div>

                        <div className={styles.div17}>
                            结束战斗时间
                        </div>

                        <div className={styles.div18}>
                            {fireRecord?.fightEndTime}
                        </div>

                        <div className={styles.div19}>
                            <div>单位</div>
                            <div>概况</div>
                        </div>

                        <div className={styles.div20}>
                            <div>建筑</div>
                            <div>结构</div>
                        </div>

                        <div className={styles.div21}>
                            {fireRecord?.buildingStructure}
                        </div>

                        <div className={styles.div22}>
                            <div>耐火</div>
                            <div>等级</div>
                        </div>

                        <div className={styles.div23}>
                            {fireRecord?.fireResistanceRating}
                        </div>

                        <div className={styles.div24}>
                            <div>建筑</div>
                            <div>层数</div>
                        </div>

                        <div className={styles.div25}>
                            {fireRecord?.buildingLayer}
                        </div>

                        <div className={styles.div26}>
                            <div>建筑</div>
                            <div>面积</div>
                        </div>

                        <div className={styles.div27}>
                            {fireRecord?.buildingArea + ' m²'}
                        </div>

                        <div className={styles.div28}>
                            <div>烧毁</div>
                            <div>财务</div>
                        </div>

                        <div className={styles.div29}>
                            {fireRecord?.burningProperty}
                        </div>
                        <div className={styles.div30}>
                            过火面积
                        </div>
                        <div className={styles.div31}>
                            {fireRecord?.burningArea + ' m²'}
                        </div>
                        <div className={styles.div32}>
                            过火间数
                        </div>
                        <div className={styles.div33}>
                            {fireRecord?.buringRoomNum}
                        </div>
                        <div className={styles.div34}>
                            涉及户数
                        </div>
                        <div className={styles.div35}>
                            {fireRecord?.numberOfHouse}
                        </div>
                        <div className={styles.div36}>
                            有无纠纷
                        </div>
                        <div className={styles.div37}>
                            {fireRecord?.hasDispute}
                        </div>
                        <div className={styles.div38}>
                            <div>
                                火
                            </div>
                            <div>
                                场
                            </div>
                            <div>
                                情
                            </div>
                            <div>
                                况
                            </div>
                        </div>
                        <div className={styles.div39}>
                            <div style={{marginLeft: '10px', marginTop: '10px'}}>
                                {fireRecord?.fireSituation}
                            </div>
                        </div>
                        <div className={styles.div40}>
                            <div>影像</div>
                            <div>资料</div>
                        </div>
                        <div className={styles.div41}>
                            {'录像___' + fireRecord?.videoNum + '___份, 照片___' + fireRecord?.imageNum + '___张, 现场平面图___' + fireRecord?.sitePlanNum + '___份'}
                        </div>
                        <div className={styles.div42}>
                            填写人
                        </div>
                        <div className={styles.div43}>
                            <img src={fireRecord?.writePersonUrl} width={100} height={100} alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.rightArea}>
                    <Button style={{marginTop: '4vh'}}
                            type={'primary'} onClick={printDocument}>导出为pdf
                    </Button>
                </div>
            </div>
            <div style={{marginTop:'30px',marginBottom:'30px',marginLeft:'10vw'}}>
                <Form>
                    <Form.Item label={"火灾定位"}>
                        <div
                            className={styles.content1}>
                            <img style={{marginLeft: '10px', cursor: 'pointer'}} width={25} height={25}
                                 src={locationIcon}
                                 alt="" onClick={() => {
                                setLocationModalOpen(true);
                            }}/>
                        </div>
                    </Form.Item>
                    <Form.Item label={"火灾照片"}>
                        {fireRecord?.fireRecordPictures.map((image: any) => (
                            <div>
                            <Image
                                style={{paddingTop:'20px'}}
                                key={image.key}
                                width={200}
                                height={200}
                                src={image.url}
                                preview={{
                                    scaleStep: 0.5,
                                    src: image.src,
                                }}
                            />
                            </div>
                        ))}
                    </Form.Item>
                </Form>
            </div>
            <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                           lat={fireRecord?.fireRecordLat} lng={fireRecord?.fireRecordLng}/>
        </div>

    );
};

export default SafeCheckRecord;