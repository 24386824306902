import React, {useEffect, useRef, useState} from 'react';
import VanillaTilt from 'vanilla-tilt';
import styles from "./index.module.css"
import {useNavigate} from "react-router-dom";
import exitIcon from "../../assets/icons/exit.svg"
import {Tooltip} from 'antd';

//倾斜3D
function Tilt(props: any) {
    const {options, ...rest} = props;
    let tilt: any = useRef(null);
    useEffect(() => {
        VanillaTilt.init(tilt.current, options);
    }, [options]);

    return <div ref={tilt} {...rest} />;
}

const HomePage = () => {
    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const isManager = JSON.parse(window.localStorage?.getItem("isManager") as string);
    const isDispatcher = JSON.parse(window.localStorage?.getItem("isDispatcher") as string);
    const isMaintenance = JSON.parse(window.localStorage?.getItem("isMaintenance") as string);
    const isNormalUser = JSON.parse(window.localStorage?.getItem("isNormalUser") as string);
    const isFactory = JSON.parse(window.localStorage?.getItem("isFactory") as string);
    const isService = JSON.parse(window.localStorage?.getItem("isService") as string);
    const isDeptManager = JSON.parse(window.localStorage?.getItem("isDeptManager") as string);
    const isCompanyManager = JSON.parse(window.localStorage?.getItem("isCompanyManager") as string);
    const isDeptAssetManager = JSON.parse(window.localStorage?.getItem("isDeptAssetManager") as string);

    const navigate = useNavigate();
    const options = {
        max: 15,
        speed: 400,
        glare: true,
        "max-glare": 1
    };

    const [total, setTotal] = useState<number>(0)

    const handleGetPendingList = () => {

    }
    useEffect(() => {
        handleGetPendingList();
    }, [])

    const cards = [
        {
            key: 1,
            label: "后台管理",
            path: '/manage/hydrant',
            notice: 0,
            see: 1
        },
        {
            key: 2,
            label: "地图",
            path: "/map",
            notice: 0,
            see:1
        },

    ]

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100vw',
            height: '100vh'
        }}>
            <div className={styles.GlassCard}>
                <div className={styles.title}>智慧XF管理系统</div>
                <div className={styles.container}>
                    {cards?.filter((a: any) => (a?.see))?.map((c) => (
                        c?.key === 3 ? (
                            <Tilt
                                key={c?.key} className={styles.card} options={options} onClick={() => {
                                navigate(c?.path)
                            }}>
                                {c?.notice !== 0 && (<div className={styles.tip}/>)}
                                {c?.notice !== 0 && (
                                    <div style={{color: '#ffffff', position: "absolute", top: "2%", right: "5%"}}>
                                        {c?.notice}
                                    </div>)}
                                <div className={styles.cardTitle}>
                                    {c?.label}
                                </div>
                            </Tilt>) : (
                            <Tilt key={c?.key} className={styles.card} options={options} onClick={() => {
                                navigate(c?.path, {replace: true})
                            }}>
                                {c?.notice !== 0 && (<div className={styles.tip}/>)}
                                {c?.notice !== 0 && (
                                    <div style={{color: '#ffffff', position: "absolute", top: "2%", right: "5%"}}>
                                        {c?.notice}
                                    </div>)}
                                <div className={styles.cardTitle}>
                                    {c?.label}
                                </div>
                            </Tilt>)
                    ))}
                </div>
            </div>
            <div style={{
                width: "4vw",
                backgroundColor: '#ffffff',
                color: '#000000',
                cursor: 'pointer',
                paddingTop:'10px'
            }} onClick={() => {
                window.localStorage.clear();
                navigate("/login", {replace: true});
            }}>
                <Tooltip title="退出登录">
                    <img width={20} height={20} src={exitIcon} alt=""/>
                </Tooltip>
            </div>

            <div className='loginBottom'>
                浙ICP备2023049326号-2
                <a style={{marginLeft: '20px', color: '#287ec0', cursor: 'pointer'}} href={'https://beian.miit.gov.cn/'}>工信部备案查询</a>
            </div>
        </div>

    );
};

export default HomePage;